import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PageLogin from '../modules/_geral/pages/login/page';
import Home from '../modules/_geral/pages/home/page';

const RouteApp = () => {
   return (
      <BrowserRouter>
         <Routes>
            <Route
               path='/login'
               element={<PageLogin />}
            />
            <Route
               path='*'
               element={<Navigate to='/login' />}
            />
            <Route
               path='/home'
               element={<Home />}
            />
         </Routes>
      </BrowserRouter>
   );
};

export default RouteApp;
