import React from 'react';

const Home = () => {
   return (
      <div className=''>
         <div className='w-28 h-28 bg-black'> aaaaaa </div>
      </div>
   );
};

export default Home;
