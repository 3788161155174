import './App.css';
import RouteApp from './routes/routes';

function App() {
   return (
      <div className='App'>
         <RouteApp />
      </div>
   );
}

export default App;
