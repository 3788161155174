import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AiTwotoneMail } from 'react-icons/ai';
import { RiLockPasswordFill } from 'react-icons/ri';
import { PiMoonDuotone, PiSunDuotone } from 'react-icons/pi';

const PageLogin = () => {
   const navigate = useNavigate();

   Cookies.remove('token');
   Cookies.remove('user');
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState('');

   const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
   };

   const handleEmailChange = (e) => {
      const email = e.target.value;
      setUsername(email);
      if (email && !validateEmail(email)) {
         setError('E-mail inválido');
      } else {
         setError('');
      }
   };

   const handleLogin = async (e) => {
      e.preventDefault();
      try {
         const response = await fetch(
            `${process.env.REACT_APP_LINK_API}/login`,
            {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
                  login: username,
                  senha: password,
               },
            },
         );

         if (response.ok) {
            const data = await response.json();
            Cookies.set('user_token', data.user_token);
            navigate('/home');
         } else {
            alert('Login falhou. Verifique suas credenciais.');
         }
      } catch (error) {
         console.error('Erro ao fazer login:', error);
         alert('Ocorreu um erro ao fazer login.');
      }
   };

   return (
      <div className='flex justify-center items-center w-screen h-screen sm:w-full sm:h-full'>
         <form
            className='flex items-center justify-start flex-col lg:mt-20 py-12 px-12 bg-neutral lg:rounded-box shadow-xl h-full w-screen lg:w-1/3'
            onSubmit={handleLogin}
         >
            <div className='self-end'>
               <label className='cursor-pointer grid place-items-center'>
                  <input
                     type='checkbox'
                     value='synthwave'
                     className='toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2'
                  />
                  <PiSunDuotone className='animate-spin animate-infinite animate-duration-[5000ms] animate-ease-linear col-start-1 row-start-1 stroke-base-100 fill-base-100' />
                  <PiMoonDuotone className='animate-spin animate-infinite animate-duration-[5000ms] animate-ease-linear col-start-2 row-start-1 stroke-base-100 fill-base-100' />
               </label>
            </div>
            <div className='flex flex-col justify-center items-center'>
               <h1 className='text-3xl text-neutral-content font-bold text-center mb-4'>
                  Report's
               </h1>
               <p className='flex justify-center items-center w-80 text-center mb-8 font-semibold text-neutral-content tracking-normal'>
                  Bem vindo! <br /> Por favor, efetue o login.
               </p>
            </div>
            <div className='form-control flex items-center justify-center'>
               <div className='space-y-4 w-80'>
                  <label className='input input-bordered flex items-center gap-2'>
                     <div className='text-base font-bold w-14 lg:w-12'>
                        E-mail
                     </div>
                     <div>
                        <AiTwotoneMail />
                     </div>
                     <input
                        type='email'
                        placeholder='Informe seu e-mail'
                        className='block text-sm py-3 px-4'
                        value={username}
                        onChange={handleEmailChange}
                        autoComplete='username'
                     />
                  </label>
                  {error && (
                     <p className='text-neutral-content text-sm'>{error}</p>
                  )}
                  <label className='input input-bordered flex items-center gap-2'>
                     <div className='text-base font-bold w-14 lg:w-12'>
                        Senha
                     </div>
                     <div>
                        <RiLockPasswordFill />
                     </div>
                     <input
                        type='password'
                        placeholder='Informe sua senha'
                        className='block text-sm py-3 px-4'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete='current-password'
                     />
                  </label>
               </div>
               <div className='text-center mt-6'>
                  <div className='form-control mt-1'>
                     <button
                        type='submit'
                        className='flex justify-center items-center h-12 w-80 rounded-lg border text-primary-content hover:text-secondary-content bg-primary hover:bg-secondary'
                     >
                        <div className='text-center text-xl font-semibold tracking-wide'>
                           Login
                        </div>
                     </button>
                  </div>
               </div>
               <div className='mt-4 text-sm'>
                  <a
                     href='http://helpdesk.klin.com.br/'
                     className='label-text-alt link link-hover text-sm'
                  >
                     <div className='text-neutral-content link'>
                        Clique aqui, para recuperar sua senha!
                     </div>
                  </a>
               </div>
            </div>
         </form>
         <div className='absolute w-60 h-60 rounded-box bg-primary -top-5 -left-16 z-0 transform rotate-45 hidden lg:block'></div>
         <div className='absolute w-48 h-48 rounded-box bg-secondary bottom-28 right-28 z-0 transform rotate-12 hidden lg:block'></div>
         <div className='w-40 h-40 absolute bg-error mt-7 rounded-full top-0 right-12 hidden lg:block'></div>
         <div className='w-20 h-40 absolute bg-accent rounded-full bottom-20 left-10 transform rotate-45 hidden lg:block'></div>
      </div>
   );
};

export default PageLogin;
